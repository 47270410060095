import AdminPage from "../settings-module/pages/admin-page/AdminPage";
import DashboardPage from "../private-module/pages/dashboard-page/DashboardPage";
import LoginPage from "../public-module/pages/login-page/LoginPage";
import SubscriptionPage from "../settings-module/pages/subscription-page/SubscriptionPage";
import AccountPanel from "../settings-module/pages/subscription-page/components/AccountPanel";
import UserSetupPage from "../settings-module/pages/user-setup-page/UserSetupPage";
import { AboutPage } from "../public-module/pages/about-page/AboutPage";
import BlogArticle from "../public-module/pages/blog-article/BlogArticle";
import { BlogPage } from "../public-module/pages/blog-page/BlogPage";
import { ContactPage } from "../public-module/pages/contact-page/ContactPage";
import RodoPage from "../public-module/pages/rodo-page/RodoPage";
import StartPage from "../public-module/pages/start-page/StartPage";
import RegistrationPage from "../public-module/pages/registration-page/RegistrationPage";
import SettingsPage from "../settings-module/pages/settings-page/SettingsPage";
import AdminUsersPage from "../settings-module/pages/admin-users-page/AdminUsersPage";
import AdminConversationPage from "../settings-module/pages/admin-conversations-page/AdminConversationsPage";
import StatutePage from "../public-module/pages/statute-page/StatutePage";

type Route = {
  path: string;
  element: () => JSX.Element;
  layout: "dashboard" | "public" | "empty";
  authorization: boolean;
};

export const routes: Route[] = [
  {
    path: "/czat",
    element: DashboardPage,
    layout: "dashboard",
    authorization: true,
  },
  {
    path: "/czat/:id",
    element: DashboardPage,
    layout: "dashboard",
    authorization: true,
  },
  {
    path: "/ustawienia",
    element: SettingsPage,
    layout: "dashboard",
    authorization: true,
  },
  {
    path: "/subskrypcja",
    element: SubscriptionPage,
    layout: "dashboard",
    authorization: true,
  },
  {
    path: "/account",
    element: AccountPanel,
    layout: "dashboard",
    authorization: true,
  },
  {
    path: "/mnb",
    element: AdminPage,
    layout: "dashboard",
    authorization: true,
  },
  {
    path: "/mnb/users",
    element: AdminUsersPage,
    layout: "dashboard",
    authorization: true,
  },
  {
    path: "/mnb/czat/:id",
    element: AdminConversationPage,
    layout: "dashboard",
    authorization: true,
  },
  {
    path: "/mnb/czat",
    element: AdminConversationPage,
    layout: "dashboard",
    authorization: true,
  },
  {
    path: "/logowanie",
    element: LoginPage,
    layout: "empty",
    authorization: false,
  },
  {
    path: "/rejestracja",
    element: RegistrationPage,
    layout: "empty",
    authorization: false,
  },
  {
    path: "/dane",
    element: UserSetupPage,
    layout: "empty",
    authorization: false,
  },
  { path: "/", element: StartPage, layout: "public", authorization: false },
  { path: "/blog", element: BlogPage, layout: "public", authorization: false },
  {
    path: "/blog/:id",
    element: BlogArticle,
    layout: "public",
    authorization: false,
  },
  {
    path: "/kontakt",
    element: ContactPage,
    layout: "public",
    authorization: false,
  },
  {
    path: "/o-nas",
    element: AboutPage,
    layout: "public",
    authorization: false,
  },
  {
    path: "/polityka",
    element: RodoPage,
    layout: "empty",
    authorization: false,
  },
  {
    path: "/regulamin",
    element: StatutePage,
    layout: "empty",
    authorization: false,
  },
];
