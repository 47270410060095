import { IconButton, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import useTabs from '../../../../../../hooks/useTabs';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCssVariable } from 'styles/getVariables';

const StyledTab = styled(Tab)(({ theme }) => ({
  py: '0px',
  textTransform: 'none',
  height: '28px',
  minHeight: '28px',
  color: getCssVariable('--text-primary'),
  '& .MuiTab-wrapper': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.875rem',
    color: getCssVariable('--text-primary'),
  },
  '&.Mui-selected': {
    color: getCssVariable('--text-primary'),
    '& .MuiTabs-indicator': {
      backgroundColor: getCssVariable('--text-primary'),
    },
    '& .MuiTab-wrapper .close-icon': {
      color: getCssVariable('--text-primary'),
    },
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: getCssVariable('--text-primary'), // Set underline color
  },
}));

const NavigationMenu = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const { rulings } = useTabs();
  const { displayed, setDisplayedCase, removeRuling } = useTabs();

  const closeTab = (ruling: any) => {
    removeRuling(ruling, id);
    if (displayed === ruling) {
      setDisplayedCase('sources');
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === 'search') {
      setDisplayedCase('search');
    } else if (newValue === 'sources') {
      setDisplayedCase('sources');
    } else {
      let ruling = rulings.find((ruling) => ruling.url === newValue);
      if (ruling) {
        setDisplayedCase(ruling);
      }
    }
  };

  return (
    <StyledTabs
      className="tabSearch"
      value={
        displayed !== 'search' && displayed !== 'sources'
          ? displayed.url
          : displayed
      }
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      sx={{ padding: '0px', height: '28px', minHeight: '28px' }}
    >
      <StyledTab
        data-testid="search-tab"
        label={<SearchIcon />}
        value="search"
      />
      <StyledTab
        data-testid="sources-tab"
        label={t('dashboard:tabs.sources')}
        value="sources"
      />
      {rulings
        .filter((m) => m.type === 'law')
        .map((ruling, index) => {
          return (
            <StyledTab
              data-testid="law-tab"
              key={index}
              sx={{ mx: '8px' }}
              label={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <label style={{ marginRight: '5px' }}>
                    {ruling.year != null
                      ? ruling.year + ' / ' + ruling.number
                      : ruling.title}
                  </label>
                  <IconButton
                    size="small"
                    data-testid="close-button-id"
                    onClick={(event) => {
                      event.stopPropagation();
                      closeTab(ruling);
                    }}
                  >
                    <CloseIcon
                      fontSize="inherit"
                      sx={{ color: getCssVariable('--text-primary') }}
                    />
                  </IconButton>
                </div>
              }
              value={ruling.url}
            />
          );
        })}
      {rulings
        .filter((m) => m.type === 'ruling')
        .map((ruling, index) => {
          return (
            <StyledTab
              key={index}
              sx={{
                mx: '8px',
                background: getCssVariable('--tab-color'),
              }}
              label={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <label style={{ marginRight: '5px', paddingLeft: '5px' }}>
                    {ruling.year != null
                      ? ruling.year + ' / ' + ruling.number
                      : ruling.title}
                  </label>
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      closeTab(ruling);
                    }}
                  >
                    <CloseIcon
                      fontSize="inherit"
                      sx={{ color: getCssVariable('--text-primary') }}
                    />
                  </IconButton>
                </div>
              }
              value={ruling.url}
            />
          );
        })}
    </StyledTabs>
  );
};

export default NavigationMenu;
