import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import LoginPage from './public-module/pages/login-page/LoginPage';
import DashboardPage from './private-module/pages/dashboard-page/DashboardPage';
import RequireAuth from './components/authentication/RequireAuth';
import { AuthProvider } from './context/Auth';
import SubscriptionPage from './settings-module/pages/subscription-page/SubscriptionPage';
import MainLayout from './components/layout/main-layout/MainLayout';
import AccountPanel from './settings-module/pages/subscription-page/components/AccountPanel';
import StartPage from './public-module/pages/start-page/StartPage';
import AdminPage from './settings-module/pages/admin-page/AdminPage';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import useTextHighlight from './hooks/useTextHighlight';
import { BlogPage } from './public-module/pages/blog-page/BlogPage';
import { AboutPage } from './public-module/pages/about-page/AboutPage';
import { ContactPage } from './public-module/pages/contact-page/ContactPage';
import UserSetupPage from './settings-module/pages/user-setup-page/UserSetupPage';
import RodoPage from './public-module/pages/rodo-page/RodoPage';
import BlogArticle from './public-module/pages/blog-article/BlogArticle';
import RegistrationPage from './public-module/pages/registration-page/RegistrationPage';
import SettingsPage from './settings-module/pages/settings-page/SettingsPage';
import AdminUsersPage from './settings-module/pages/admin-users-page/AdminUsersPage';
import AdminConversationPage from './settings-module/pages/admin-conversations-page/AdminConversationsPage';
import StatutePage from './public-module/pages/statute-page/StatutePage';
import useDarkMode from 'hooks/useDarkMode';
import { getCssVariable } from 'styles/getVariables';

function App() {
  const { blockedText } = useTextHighlight();
  useDarkMode();

  const primaryTextColor = getCssVariable('--text-primary');
  const borderColor = getCssVariable('--textfield-border');
  const hoverBorderColor = getCssVariable('--text-secondary');
  const focusedColor = getCssVariable('--textfield-border-focused');

  const theme = createTheme({
    typography: {
      fontFamily: 'Lato, sans-serif',
      allVariants: {
        color: primaryTextColor,
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            userSelect: blockedText ? 'none' : 'text',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              color: primaryTextColor,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: borderColor,
              },
              '&:hover fieldset': {
                borderColor: hoverBorderColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: focusedColor,
              },
            },
          },
        },
      },

      MuiAutocomplete: {
        styleOverrides: {
          option: {
            '&[data-focus="true"]': {
              backgroundColor: getCssVariable('--highlighted-text-background'),
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: '10px',
            backgroundColor: getCssVariable('--popup-background'),
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '14px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            userSelect: blockedText ? 'none' : 'text',
          },
        },
      },
    },
    palette: {
      primary: {
        main: getCssVariable('--button-color'),
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route element={<RequireAuth />}>
                <Route path="/czat" element={<DashboardPage />} />
                <Route path="/czat/:id" element={<DashboardPage />} />
                <Route path="/account" element={<AccountPanel />} />
                <Route path="/ustawienia" element={<SettingsPage />} />
                <Route path="/subskrypcja" element={<SubscriptionPage />} />
                <Route path="/mnb" element={<AdminPage />} />
                <Route path="/mnb/users" element={<AdminUsersPage />} />
                <Route
                  path="/mnb/czat/:id"
                  element={<AdminConversationPage />}
                />
                <Route path="/mnb/czat" element={<AdminConversationPage />} />
              </Route>
              <Route path="/logowanie" element={<LoginPage />} />
              <Route path="/rejestracja" element={<RegistrationPage />} />
              <Route path="/dane" element={<UserSetupPage />} />
              <Route path="/" element={<StartPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:id" element={<BlogArticle />} />
              <Route path="/kontakt" element={<ContactPage />} />
              <Route path="/o-nas" element={<AboutPage />} />
              <Route path="/polityka" element={<RodoPage />} />
              <Route path="/regulamin" element={<StatutePage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}
export default App;
// dummy comment, to delete
