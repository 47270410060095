import { AccordionDetails, Box, Button, CircularProgress } from "@mui/material";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import axiosPrivate from "../../../../api/axios";
import { auth } from "../../../../configs/firebaseConfig";
import { RulingsSerachFilter } from "../../../models/RulingsSerachFilter";
import SearchRulingFilters from "../search-ruling-filters/SearchRulingFilters";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { RulingModel } from "../../../../models/RulingModel";
import useTabs from "../../../../hooks/useTabs";
import { useParams } from "react-router-dom";

const SearchRulingPanel = () => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [rulingsList, setRulingsList] = useState<RulingModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { addRuling, setDisplayedCase } = useTabs();
  const [searched, setSearched] = useState<boolean>(false);

  const mapJudgementType = (type: string) => {
    if (
      type.toLowerCase().includes("sentence") ||
      type.toLowerCase().includes("wyrok")
    ) {
      return "Wyrok";
    } else if (
      type.toLowerCase().includes("order") ||
      type.toLowerCase().includes("postanowienie") ||
      type.toLowerCase().includes("decision")
    ) {
      return "Postanowienie";
    } else if (
      type.toLowerCase().includes("uzasadbnienie") ||
      type.toLowerCase().includes("justification") ||
      type.toLowerCase().includes("reasons")
    ) {
      return "Uzasadnienie";
    } else {
      return "Inne";
    }
  };

  const handleSearch = async (filters: RulingsSerachFilter) => {
    setExpanded(false);
    setSearched(true);
    setLoading(true);
    const token = await auth?.currentUser?.getIdToken();

    await axiosPrivate
      .get(`/postgresdb/rulings?ruling_name=${filters.name}&type=${""}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: "*",
          "content-type": "application/json",
        },
        responseType: "json",
      })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          let ordinances: RulingModel[] = response.data.map((m: any) => {
            let jsonString = m.court_cases;
            let parsedJson = JSON.parse(jsonString);
            let caseNumber = parsedJson[0].caseNumber;
            return {
              title: caseNumber,
              description: "",
              type: "ruling",
              url: m.href,
              id: m.id,
              endIndex: -1,
              startIndex: -1,
              text: "",
              judgmentDate: m.judgment_date
                .replace(/\\/g, "")
                .replace(/"/g, ""),
              judgmentType: m.judgment_type,
            };
          });
          setRulingsList([...ordinances]);
        } else {
          setRulingsList([]);
        }
      });
    setLoading(false);
  };

  const handleShowRuling = (rul: RulingModel) => {
    addRuling(rul, id);
    setDisplayedCase(rul);
  };

  return (
    <Box maxWidth={"100%"}>
      <SearchRulingFilters onSearch={handleSearch} />
      {/* <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {!expanded ? (
            <Button
              onClick={() => setExpanded(true)}
              variant={"outlined"}
              color={"primary"}
            >
              Wyszukaj ponownie
            </Button>
          ) : (
            <></>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <SearchRulingFilters onSearch={handleSearch} />
        </AccordionDetails>
      </Accordion> */}
      <Box>
        {loading && (
          <Box
            height={"100px"}
            width={"400px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress size={"32px"} />
          </Box>
        )}
      </Box>
      <Box>
        {rulingsList.length > 0 && (
          <Typography sx={{ marginTop: "30px", marginLeft: "5px" }}>
            Znaleziono następujące wyniki:
          </Typography>
        )}
        {rulingsList.length === 0 && searched && loading === false && (
          <Typography sx={{ marginTop: "30px", marginLeft: "5px" }}>
            Nie znaleziono wyników
          </Typography>
        )}
        <Box width={"100%"} marginTop={"20px"}>
          {rulingsList.map((rul, index) => {
            return (
              <Box
                component={"button"}
                sx={{
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "lightGray",
                  },
                }}
                key={index}
                width={"100%"}
                p={"10px"}
                borderBottom={"1px solid #DBDBDB"}
                onClick={() => {
                  handleShowRuling(rul);
                }}
                alignContent={"left"}
                alignItems={"left"}
              >
                <Box display={"flex"}>
                  <Typography align="left" sx={{ width: "200px" }}>
                    {rul.title}
                  </Typography>
                  <Typography align="left">{rul.judgmentDate}</Typography>
                </Box>
                <Typography align="left" fontSize={"12px"} fontWeight={500}>
                  {mapJudgementType(rul.judgmentType ?? "")}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchRulingPanel;
