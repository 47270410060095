import React, { useCallback } from 'react';
import { Box, IconButton } from '@mui/material';
import ParagraphView, { ParagraphModel } from './ParagraphView';
import assets from '../../../../../../../../assets';
import colors from '../../../../../../../../configs/colorConfig';
import { PathModel } from '../../LawPanel';
import { getCssVariable } from 'styles/getVariables';

export interface ArticleModel {
  name: string;
  text: string;
  highlighted?: boolean;
  paragraphs: ParagraphModel[];
  numberOfRulings: number;
}

type Props = {
  article: ArticleModel;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rulingsNumber: number,
    path: PathModel
  ) => void;
};

const ArticleView: React.FC<Props> = React.memo(({ article, onClick }: Props) => {
  const highlightedColor = getCssVariable('--highlighted-text-background');

  const handleClick = useCallback((
    event: React.MouseEvent<HTMLButtonElement>,
    numberOfRulings: number,
    path?: PathModel
  ) => {
    let model: PathModel = {
      article: '',
    };

    if (path != null) {
      model = path;
    }
    model.article = article.name;

    onClick(event, numberOfRulings, model);
  }, [article.name, onClick]);

  const renderHeader = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {article.numberOfRulings > 0 ? (
        <IconButton
          id={'art' + article.name.replaceAll(' ', '')}
          onClick={(e) => handleClick(e, article.numberOfRulings)}
        >
          <Box
            borderRadius={'5px'}
            component={'img'}
            src={assets.icons.hammerBlack}
            width="15px"
            height={'15px'}
          />
        </IconButton>
      ) : (
        <IconButton disabled={true} sx={{ padding: '6px' }}>
          <Box width="15px" height={'15px'} />
        </IconButton>
      )}
      <div
        className="article"
        dangerouslySetInnerHTML={{
          __html: '<h4><strong>' + article.name + '</strong></h4>',
        }}
      />
    </div>
  );

  const renderBody = () => (
    <>
      <div
        className={
          article.highlighted && article.name !== ''
            ? 'highlightedDiv'
            : 'normalDiv'
        }
        style={{ marginLeft: '30px' }}
        dangerouslySetInnerHTML={{ __html: article.text }}
      />
      {article.paragraphs.map((paragraph, indexParagraph) => {
        return (
          <div
            key={`${article.name}-paragraph-${indexParagraph}-${paragraph.name}`}
            style={{
              backgroundColor:
                paragraph.highlighted === true &&
                article.highlighted === false &&
                paragraph.name !== ''
                  ? highlightedColor
                  : '',
              border:
                paragraph.highlighted === true &&
                article.highlighted === false &&
                paragraph.name !== ''
                  ? '1px solid gray'
                  : '',
              borderRadius: '10px',
              paddingRight: '5px',
            }}
          >
            <ParagraphView
              articleName={article.name}
              key={indexParagraph}
              paragraph={paragraph}
              onClick={handleClick}
            />
          </div>
        );
      })}
    </>
  );

  return (
    <div
      style={{
        backgroundColor:
          article.highlighted && article.name !== ''
            ? getCssVariable('--highlighted-text-background')
            : '',
        border:
          article.highlighted && article.name !== '' ? '1px solid gray' : '',
        borderRadius: '10px',
        paddingRight: '5px',
      }}
    >
      <div style={{ marginBottom: '0px' }}>
        {renderHeader()}
        {renderBody()}
      </div>
    </div>
  );
});

export default ArticleView;
