import {
  Box,
  Button,
  CircularProgress,
  Menu,
  Modal,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import { Theme, CSSObject } from '@mui/material/styles';
import colors from '../../../configs/colorConfig';
import LoginControls from '../../common/login-controls/LoginControls';
import { ConversationModel } from '../../../models/ConversationModel';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { auth, firestore } from '../../../configs/firebaseConfig';
import AddIcon from '@mui/icons-material/Add';
import useSidebar from '../../../hooks/useSidebar';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { ListOfConversations } from './components/ListOfConversations';
import { SidebarListOfConversations } from './components/SidebarListOfConverstion';
import useTabs from '../../../hooks/useTabs';
import ReportForm from './components/ReportForm';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import assets from '../../../assets';
import useInstruction from '../../../hooks/useInstruction';
import { getCssVariable } from 'styles/getVariables';

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const SideBar = () => {
  const [conversationList, setConversationList] = useState<ConversationModel[]>(
    []
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const { setRulingList } = useTabs();
  const navigate = useNavigate();
  const { toasts } = useSidebar();
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const { setShowInstruction } = useInstruction();

  const handleScroll = (
    sourceDiv: HTMLDivElement | null,
    targetDiv: HTMLDivElement | null
  ) => {
    if (sourceDiv && targetDiv) {
      sourceDiv.scrollTop = targetDiv.scrollTop;
    }
  };

  const fetchConversations = async () => {
    let docList: ConversationModel[] = [];
    if (auth.currentUser?.uid == null) return;
    setLoading(true);
    const querySnapshot = await getDocs(
      query(
        collection(firestore, 'Conversations'),
        where('uid', '==', auth.currentUser?.uid),
        orderBy('last_appended', 'desc')
      )
    );
    querySnapshot.forEach((doc) => {
      if (doc.data().messages && doc.data().messages.length > 0) {
        docList.push({
          id: doc.id,
          title: doc.data().messages[1].content.substring(0, 100),
          messages: doc.data().messages,
          lastAppended: doc.data().last_appended.toDate(),
        });
      }
    });
    setLoading(false);
    setConversationList(docList);
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  useEffect(() => {
    if (toasts.length > 0) {
      fetchConversations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toasts]);

  const handleClick = (conversation: ConversationModel) => {
    navigate(`/czat/${conversation.id}`);
  };

  const handleChatClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteClick = (conversation: ConversationModel) => {
    const docRef = doc(firestore, 'Conversations', conversation.id);
    deleteDoc(docRef)
      .then(() => {
        fetchConversations();
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Drawer
      className="backgroundSidebar"
      variant="permanent"
      PaperProps={{
        sx: {
          backgroundColor: getCssVariable('--navbar-color'),
          color: colors.sidebarLeft.text,
        },
      }}
    >
      <DrawerHeader>
        <IconButton
          onClick={() => {
            navigate('/');
          }}
          sx={{ marginLeft: '15px', marginTop: '15px', padding: '0px' }}
        >
          <Box
            component="img"
            className="sidebarMainIcon"
            src={assets.icons.logo}
            width={'50px'}
            height={'50px'}
          />
        </IconButton>
      </DrawerHeader>
      <div
        ref={div1Ref}
        onScroll={() => handleScroll(div1Ref.current, div2Ref.current)}
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
      >
        <List sx={{ paddingLeft: '0px' }}>
          <ListItem
            key={'Konwersacje'}
            disablePadding
            sx={{
              paddingLeft: '0px',
              margin: '2px',
              display: 'block',
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                display: 'flex',
                justifyContent: 'center',
              }}
              disabled={loading}
              onClick={handleChatClick}
            >
              <ListItemIcon
                sx={{
                  display: 'flex',
                  minWidth: 0,
                  justifyContent: 'center',
                  color: colors.sidebarLeft.text,
                }}
              >
                <QuestionAnswerIcon className="questionAnwserIcon" />
              </ListItemIcon>
            </ListItemButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <div
                ref={div2Ref}
                onScroll={() => handleScroll(div1Ref.current, div2Ref.current)}
                style={{ overflow: 'auto', maxHeight: 'calc(100vh - 150px)' }}
              >
                <ListOfConversations
                  conversations={conversationList}
                  onClick={(c) => {
                    handleClick(c);
                  }}
                  onDeleteClick={(c) => {
                    handleDeleteClick(c);
                  }}
                />
              </div>
            </Menu>
            <>
              {loading ? (
                <Box display={'flex'} justifyContent={'center'}>
                  <CircularProgress sx={{ color: 'white' }} size={'22px'} />
                </Box>
              ) : (
                <SidebarListOfConversations
                  conversationList={conversationList}
                  onClick={(c) => {
                    handleClick(c);
                  }}
                />
              )}
            </>
          </ListItem>
        </List>
      </div>
      <div style={{ flexGrow: 1 }}>
        {
          <Tooltip title="Rozpocznij nową konwersację" placement="right">
            <Box
              px={'18px'}
              py={'11px'}
              display={'flex'}
              justifyContent={'center'}
            >
              <IconButton
                onClick={() => {
                  setRulingList([]);
                  navigate(`/czat`);
                }}
              >
                <AddIcon sx={{ color: 'white' }} />
              </IconButton>
            </Box>
          </Tooltip>
        }
      </div>
      <Tooltip title="Samouczek" placement="right">
        <Box
          width={'100%'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
          paddingBottom={2}
        >
          <IconButton
            onClick={() => {
              setShowInstruction(true);
            }}
          >
            <NotListedLocationIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title="Zgłoś błąd w aplikacji" placement="right">
        <Box
          width={'100%'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
          paddingBottom={2}
        >
          <IconButton
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <FmdBadIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title="Konto" placement="right">
        <Box
          width={'100%'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
          paddingBottom={2}
        >
          <LoginControls extended={false} />
        </Box>
      </Tooltip>

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            height: 400,
            bgcolor: 'background.paper',
            border: '1px solid #ddd',
            borderRadius: '10px',
            boxShadow: 24,
            p: 2,
          }}
        >
          <ReportForm
            onClose={() => {
              setOpenModal(false);
            }}
            onSend={() => {
              setOpenConfirmationModal(true);
              setOpenModal(false);
            }}
          />
        </Box>
      </Modal>

      <Modal
        open={openConfirmationModal}
        onClose={() => {
          setOpenConfirmationModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 500,
            bgcolor: 'background.paper',
            border: '1px solid #ddd',
            borderRadius: '10px',
            boxShadow: 24,
            p: 2,
          }}
        >
          <Box display={'flex'} justifyContent={'center'} p={1}>
            <Typography id="modal-modal-title" fontSize={'18px'}>
              Dziękujemy za zgłoszenie
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={'center'} p={1}>
            <Button
              size={'small'}
              variant="contained"
              onClick={() => {
                setOpenConfirmationModal(false);
              }}
            >
              Zamknij
            </Button>
          </Box>
        </Box>
      </Modal>
    </Drawer>
  );
};

export default SideBar;
