import {
  Box,
  Button,
  Grid,
  GridProps,
  Typography,
  styled,
} from '@mui/material';
import assets from '../../../../../../assets';
import { RulingModel } from '../../../../../../models/RulingModel';
import useTextHighlight from '../../../../../../hooks/useTextHighlight';
import { getCssVariable } from 'styles/getVariables';

const MainGrid = styled(Grid)<GridProps>({});

type Props = {
  source: RulingModel;
  gridProps?: GridProps;
  onSourceSelect: (source: RulingModel) => void;
};
const RulingItem = ({ source, gridProps, onSourceSelect }: Props) => {
  const { blockedText } = useTextHighlight();

  return (
    <MainGrid {...gridProps} container>
      <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{ marginBottom: '0px' }}
        >
          <Box>
            <Button
              sx={{
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: '700',
                paddingLeft: '0px',
                color: getCssVariable('--text-primary'),
              }}
              onClick={() => {
                onSourceSelect(source);
              }}
            >
              {source.title}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{ userSelect: blockedText ? 'none' : 'text' }}
          component={'img'}
          src={assets.icons.hammer}
          width="20px"
          height={'20px'}
        />
      </Grid>

      <Grid item xs={12} display={'flex'}>
        <Typography fontSize={'14px'}>{source.text}</Typography>
      </Grid>
    </MainGrid>
  );
};

export default RulingItem;
