import React, { createContext, useEffect, useState } from "react";
import { auth } from "../configs/firebaseConfig";
import { User } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

interface PropsAuthProvider {
  children: React.ReactNode;
}

interface AuthContextType {
  currentUser: User | null;
  userRole: number | null;
}

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  userRole: null,
});

export const AuthProvider = ({ children }: PropsAuthProvider) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userRole, setUserRole] = useState<number | null>(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const fetchUserRole = async (user: User) => {
      const db = getFirestore();
      const userRoleDoc = await getDoc(doc(db, "Roles", user.uid));
      if (userRoleDoc.exists()) {
        setUserRole(userRoleDoc.data().role);
      } else {
        setUserRole(null);
      }
    };

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        await fetchUserRole(user);
      }
      setPending(false);
    });

    return () => unsubscribe();
  }, []);

  if (pending) {
    return <></>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
