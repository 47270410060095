import { ArticleModel } from '../private-module/components/organisms/right-side-bar/components/law-panel/components/law-parts/ArticleView';
import { createElementModelForTree } from '../private-module/components/organisms/right-side-bar/components/law-panel/helperFunctions';
import {
  sortParts,
  sortBooks,
  sortTitles,
  sortSections,
} from '../private-module/components/organisms/right-side-bar/components/law-panel/sorting';
import { CaseModel } from './CaseModel';
import { RulingModel } from './RulingModel';

const sortAlphaNum = (a: string, b: string) =>
  a.localeCompare(b, 'en', { numeric: true });

const sectionNames = [
  'books',
  'titles',
  'sections',
  'chapters',
  'subchapters',
  'articles',
  'paragraphs',
  'points',
  'letters',
];

const createElementModel = (
  name: string,
  data: any,
  match: boolean,
  sectionName:
    | 'books'
    | 'titles'
    | 'sections'
    | 'chapters'
    | 'subchapters'
    | 'articles'
    | 'points'
    | 'paragraphs'
    | 'letters'
) => {
  let childIndex = sectionNames.indexOf(sectionName);
  let childName = '';
  if (childIndex < sectionNames.length - 1) {
    childName = sectionNames[childIndex + 1];
  }

  let text = '';

  if (sectionName === 'articles') {
    if (data[name][''] && data[name]['']['']) {
      text = data[name][''][''][''];
    }
  } else if (sectionName === 'paragraphs') {
    if (data[name]['']) {
      text = data[name][''][''];
    }
  } else if (sectionName === 'points') {
    if (data[name]) {
      text = data[name][''];
    }
  } else if (sectionName === 'letters') {
    if (data[name]) {
      text = data[name];
    }
  }

  let displayText = '';

  if (sectionName !== 'articles') {
    displayText = text;
  } else {
    displayText = text === '' ? '' : text;
  }

  let output;
  if (childName !== '') {
    output = {
      name: name,
      text: displayText,
      highlighted: match,
      [childName]: [],
    };
  } else {
    output = {
      name: name,
      text: displayText,
      highlighted: match,
    };
  }

  return output;
};

function isEmpty(obj: any) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

/**Model reprezentujący ustawę */
export interface LawModel extends CaseModel {
  number: number;
  year: number;
  longTitle: string;
  page: number;
  rulings: RulingModel[];
  text?: any;
  highlightedArticle?: string;
  highlightedParagraph?: string;
  pathJson?: string;
  rulingsLoading?: boolean;
}

/** Model reprezentujący ustawę w formacie zwracanym przez backend */
interface LawBackendModel {
  nr: number;
  year: number;
  unified: string;
  title: string;
  page: number;
  law_entity_structure?: string;
  law_entity_path: string;
  path_json?: string;
}

export interface PartModel {
  name: string;
  text: string;
  books: {
    name: string;
    text: string;
    titles: {
      name: string;
      text: string;
      sections: {
        name: string;
        text: string;
        chapters: {
          name: string;
          text: string;
          subchapters: {
            name: string;
            text: string;
            articles: {
              name: string;
              text: string;
              numberOfRulings: number;
              paragraphs: {
                name: string;
                text: string;
                highlighted?: boolean;
                numberOfRulings: number;
                points: {
                  name: string;
                  text: string;
                  highlighted?: boolean;
                  numberOfRulings: number;
                  letters: {
                    name: string;
                    text: string;
                    highlighted?: boolean;
                    numberOfRulings: number;
                  }[];
                }[];
              }[];
            }[];
          }[];
        }[];
      }[];
    }[];
  }[];
}

/**Funkcja konwertująca obiekt zwracany przez backend na obiekt modelu */
export const lawFromMap = (data: LawBackendModel) => {
  let highlightedElement;
  let highlightedParagraphElement;
  //eslint-disable-next-line
  let textData = eval(data.law_entity_path);
  if (textData.length === 7) {
    highlightedElement = textData[textData.length - 1];
  } else if (textData.length === 8) {
    highlightedElement = textData[textData.length - 2];
    highlightedParagraphElement = textData[textData.length - 1];
  }
  //     let paragraphN = textData[textData.length - 1]
  //     highlightedParagraphElement = paragraphN.slice(2, paragraphN.length - 2);
  // }

  let law: LawModel = {
    number: data.nr,
    year: data.year,
    highlightedArticle: highlightedElement,
    highlightedParagraph: highlightedParagraphElement,
    longTitle: data.title,
    title: `Dz. U. ${data.year} poz. ${data.nr}`,
    page: data.page ?? 0,
    url: data.unified,
    type: 'law',
    rulings: [],
    pathJson: data.path_json,
    text: getModelFromExcerpt(
      data.law_entity_structure,
      data.law_entity_path,
      highlightedElement ?? ''
    ),
  };
  return law;
};

const getModelFromExcerpt = (
  text: string | undefined,
  data: string,
  articleName: string
) => {
  //eslint-disable-next-line
  let lenghtOfTree = eval(data).length;

  let output = '';

  try {
    if (lenghtOfTree === 7) {
      output = getModelFromExcerptArticle(text, articleName);
    } else if (lenghtOfTree === 8) {
      output = getModelFromExcerptArticle(text, articleName);
    }
  } catch (error) {
    console.log(error);
  }

  return output;
};

const getModelFromExcerptArticle = (text: any | undefined, title: string) => {
  let chapter: any = {
    name: '',
    articles: [],
  };
  if (text && !isEmpty(text) && isEmpty(text) === false) {
    let obj: any = {};
    if (text.length > 0 && text[0] === '{') {
      obj = JSON.parse(text);
    } else {
      obj = text;
    }

    let chapter: any = {
      name: '',
      articles: [],
    };

    let article = Object.keys(obj)[0];

    let articleModel: any = createElementModel(article, obj, false, 'articles');

    let dataForParagraphs = obj[article];
    Object.keys(dataForParagraphs)
      .sort(sortAlphaNum)
      .forEach((paragraph: any) => {
        let paragraphModel: any = createElementModel(
          paragraph,
          dataForParagraphs,
          false,
          'paragraphs'
        );
        let dataForPoints = obj[article][paragraph];
        Object.keys(dataForPoints)
          .sort(sortAlphaNum)
          .forEach((point: any) => {
            let pointModel: any = createElementModel(
              point,
              dataForPoints,
              false,
              'points'
            );
            let dataForLetters = obj[article][paragraph][point];
            Object.keys(dataForLetters)
              .sort(sortAlphaNum)
              .forEach((letter: any) => {
                let letterModel: any = createElementModel(
                  letter,
                  dataForLetters,
                  false,
                  'letters'
                );
                if (letterModel.name !== '') {
                  pointModel.letters.push(letterModel);
                }
              });
            if (pointModel.name !== '') {
              paragraphModel.points.push(pointModel);
            }
          });
        if (paragraphModel.name !== '') {
          articleModel.paragraphs.push(paragraphModel);
        } else {
          articleModel.paragraphs.push({ ...paragraphModel, text: '' });
        }
      });

    chapter.articles.push(articleModel);
    return chapter;
  }
  return chapter;
};

const findFittinRulling = (
  list: any[],
  articleName: string,
  paragraphName: string,
  pointName: string,
  letterName: string
) => {
  let number = list.find(
    (m) =>
      m['0'][6] === articleName &&
      m['0'][7] === paragraphName &&
      m['0'][8] === pointName &&
      m['0'][9] === letterName
  );
  if (number != null) {
    return number[1];
  }
  return 0;
};

export const dataToTree = (
  givenData: any,
  law: LawModel,
  rulingsPaths: any[]
) => {
  let listOfArticles: ArticleModel[] = [];
  let indexList: string[] = [];
  var keysSorted = Object.keys(givenData).sort(sortParts);
  let sections: PartModel[] = [];
  keysSorted.forEach((key: any, partIndex: number) => {
    let partModel: PartModel = {
      name: key,
      text: '',
      books: [],
    };
    let dataForBooks = givenData[key];
    let books = Object.keys(dataForBooks).sort(sortBooks);
    books.forEach((book: any, bookIndex: number) => {
      let bookModel: any = createElementModelForTree(
        book,
        dataForBooks,
        false,
        'books'
      );
      let dataForTitles = givenData[key][book];
      let titles = Object.keys(dataForTitles).sort(sortTitles);
      titles.forEach((title: any, titleIndex: number) => {
        let titleModel: any = createElementModelForTree(
          title,
          dataForTitles,
          false,
          'titles'
        );
        let dataForSections = givenData[key][book][title];
        Object.keys(dataForSections)
          .sort(sortSections)
          .forEach((section: any, sectionIndex: number) => {
            let sectionModel: any = createElementModelForTree(
              section,
              dataForSections,
              false,
              'sections'
            );
            let dataForChapters = givenData[key][book][title][section];
            Object.keys(dataForChapters)
              .sort(sortAlphaNum)
              .forEach((chapter: any, chapterIndex: number) => {
                let chapterModel: any = createElementModelForTree(
                  chapter,
                  dataForChapters,
                  false,
                  'chapters'
                );
                let dataForSubchapters =
                  givenData[key][book][title][section][chapter];
                Object.keys(dataForSubchapters)
                  .sort(sortAlphaNum)
                  .forEach((subchapter: any, subchapterIndex: number) => {
                    let subchapterModel: any = createElementModelForTree(
                      subchapter,
                      dataForSubchapters,
                      false,
                      'subchapters'
                    );

                    let dataFoArticles =
                      givenData[key][book][title][section][chapter][subchapter];

                    Object.keys(dataFoArticles)
                      .sort(sortAlphaNum)
                      .forEach((article: any, articleIndex: number) => {
                        let match = false;
                        if (
                          law.highlightedArticle?.replaceAll('"', '').trim() ===
                            article.trim() &&
                          (law.highlightedParagraph == null ||
                            law.highlightedParagraph.trim() === '')
                        ) {
                          match = true;
                        }
                        let articleModel: any = createElementModelForTree(
                          article,
                          dataFoArticles,
                          match,
                          'articles'
                        );
                        let dataForParagraphs =
                          givenData[key][book][title][section][chapter][
                            subchapter
                          ][article];
                        Object.keys(dataForParagraphs)
                          .sort(sortAlphaNum)
                          .forEach((paragraph: any) => {
                            let matchParagraph = false;
                            if (
                              law.highlightedParagraph &&
                              law.highlightedParagraph
                                ?.replaceAll('"', '')
                                .trim() === paragraph.trim() &&
                              law.highlightedArticle
                                ?.replaceAll('"', '')
                                .trim() === article.trim()
                            ) {
                              matchParagraph = true;
                            }

                            let paragraphModel: any = createElementModelForTree(
                              paragraph,
                              dataForParagraphs,
                              matchParagraph,
                              'paragraphs'
                            );
                            let dataForPoints =
                              givenData[key][book][title][section][chapter][
                                subchapter
                              ][article][paragraph];
                            Object.keys(dataForPoints)
                              .sort(sortAlphaNum)
                              .forEach((point: any) => {
                                let pointModel: any = createElementModelForTree(
                                  point,
                                  dataForPoints,
                                  match,
                                  'points'
                                );
                                let dataForLetters =
                                  givenData[key][book][title][section][chapter][
                                    subchapter
                                  ][article][paragraph][point];
                                Object.keys(dataForLetters)
                                  .sort(sortAlphaNum)
                                  .forEach((letter: any) => {
                                    let letterModel: any =
                                      createElementModelForTree(
                                        letter,
                                        dataForLetters,
                                        match,
                                        'letters'
                                      );
                                    if (letter !== '') {
                                      let number = findFittinRulling(
                                        rulingsPaths,
                                        articleModel.name,
                                        paragraphModel.name,
                                        pointModel.name,
                                        letterModel.name
                                      );
                                      letterModel.numberOfRulings = number;
                                    }
                                    pointModel.letters.push(letterModel);
                                  });
                                if (pointModel.name !== '') {
                                  let number = findFittinRulling(
                                    rulingsPaths,
                                    articleModel.name,
                                    paragraphModel.name,
                                    pointModel.name,
                                    ''
                                  );
                                  pointModel.numberOfRulings = number;
                                }
                                paragraphModel.points.push(pointModel);
                              });
                            if (paragraphModel.name !== '') {
                              let number = findFittinRulling(
                                rulingsPaths,
                                articleModel.name,
                                paragraphModel.name,
                                '',
                                ''
                              );
                              paragraphModel.numberOfRulings = number;
                            }
                            articleModel.paragraphs.push(paragraphModel);
                          });
                        if (articleModel.name !== '') {
                          let number = findFittinRulling(
                            rulingsPaths,
                            articleModel.name,
                            '',
                            '',
                            ''
                          );
                          articleModel.numberOfRulings = number;
                        }
                        listOfArticles.push(articleModel);
                        indexList.push(
                          partIndex.toString() +
                            bookIndex.toString() +
                            titleIndex.toString() +
                            sectionIndex.toString() +
                            chapterIndex.toString() +
                            subchapterIndex.toString() +
                            articleIndex.toString()
                        );
                        subchapterModel.articles.push(articleModel);
                      });
                    chapterModel.subchapters.push(subchapterModel);
                  });
                sectionModel.chapters.push(chapterModel);
              });
            titleModel.sections.push(sectionModel);
          });
        bookModel.titles.push(titleModel);
      });
      partModel.books.push(bookModel);
    });
    sections.push(partModel);
  });
  return {
    sections: sections,
    listOfArticles: listOfArticles,
    indexList: indexList,
  };
};
