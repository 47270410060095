import { Box, Button, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../configs/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/Auth";
import WhiteListUserAdditionPanel, {
  WhiteListUser,
} from "./WhiteListUserAdditionPanel";
import WhiteListPanel from "./WhiteListPanel";

const AdminPage = () => {
  const { userRole } = useContext(AuthContext);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [showList, setShowList] = useState(false);

  const navigate = useNavigate();

  const createUser = async (whiteListUser: WhiteListUser) => {
    if (whiteListUser != null) {
      setLoading(true);
      const querySnapshot = await getDocs(
        query(
          collection(firestore, "WhiteList"),
          where("email", "==", whiteListUser.email)
        )
      );

      if (querySnapshot.docs.length === 0) {
        await addDoc(collection(firestore, "WhiteList"), {
          creationDate: new Date(),
          email: whiteListUser.email,
          firstName: whiteListUser.firstName,
          lastName: whiteListUser.lastName,
          firm: whiteListUser.firm,
        }).then(() => {
          setText("Dodano użytkownia");
        });
      } else {
        setText("Użytkownik już istnieje w bazie");
      }
      setLoading(false);
    }
  };

  const handleClick = (whiteListUser: WhiteListUser) => {
    createUser(whiteListUser);
  };

  useEffect(() => {
    if (userRole !== 1134) {
      navigate("/");
    } else {
      setLoadingRoles(false);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Grid
      height={"100vh"}
      width={"calc(100vw - 100px)"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      alignContent={"center"}
    >
      {loadingRoles ? (
        <></>
      ) : (
        <Box>
          {showList ? (
            <>
              <Typography variant={"h3"} fontSize="16px" textAlign={"center"}>
                {text}
              </Typography>
              <WhiteListUserAdditionPanel
                onClick={handleClick}
                loading={loading}
              />
            </>
          ) : (
            <WhiteListPanel />
          )}

          <Button
            fullWidth
            variant="text"
            onClick={() => {
              setShowList(!showList);
            }}
          >
            {showList ? "Lista użytkowników" : "Formularz"}
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default AdminPage;
