import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { LawModel } from '../../../../../../../../models/LawModel';
import { RulingModel } from '../../../../../../../../models/RulingModel';
import useTabs from '../../../../../../../../hooks/useTabs';
import {
  auth,
  firestore,
} from '../../../../../../../../configs/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import axios from '../../../../../../../../api/axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PathModel } from '../../LawPanel';
import SynthesisAccordion from './SynthesisAccordion';
import useConversotion from 'hooks/useConversation';

type Props = {
  law: LawModel;
  endpoint: string;
  path: PathModel;
  onClick: (source: RulingModel) => void;
  onRulingLoad: (ruling: RulingModel[]) => void;
  loadedRulings: RulingModel[];
  numberOfRulings: number;
};

interface DataToSend {
  conversation_id: string | undefined;
  entity_path: string;
  act_unified_url: string;
  synthesis_embedding?: string;
}

const getText = (data: PathModel) => {
  if (data.letter != null && data.letter !== '') {
    return 'literą';
  } else if (data.point != null && data.point !== '') {
    return 'punktem';
  } else if (data.paragraph != null && data.paragraph !== '') {
    return 'paragrafem';
  } else {
    return 'artykułem';
  }
};

const ListOfConnectedRulings = ({
  law,
  path,
  endpoint,
  onClick,
  onRulingLoad,
  loadedRulings,
  numberOfRulings,
}: Props) => {
  const { addRuling, setDisplayedCase } = useTabs();
  const { vector } = useConversotion();
  const [ordinances, setOrdinances] = useState<RulingModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [synthesis, setSynthesis] = useState<string>('');
  const { id } = useParams();

  function isEmpty(value: any) {
    return (
      value == null || (typeof value === 'string' && value.trim().length === 0)
    );
  }

  const fetchSynthesis = async (id: string) => {
    const docRef = doc(firestore, 'Conversations', id);

    await getDoc(docRef).then((doc) => {
      let s = doc.data()?.synthesis;
      setSynthesis(s);
    });
  };

  const getRulings = async () => {
    let pathText = JSON.stringify(
      [path.article, path.paragraph, path.point, path.letter].filter(
        (el) => el != null
      )
    );
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      let headers = {
        Authorization: `Bearer ${token}`,
        accept: '*',
        'content-type': 'application/json',
      };
      let list: RulingModel[] = [];
      setLoading(true);
      let dataToSend: DataToSend = {
        conversation_id: id ?? '',
        entity_path: pathText,
        act_unified_url: law.url,
        synthesis_embedding: vector
      };

      await axios
        .post(`lawmodel/rulings`, dataToSend, { headers: headers })
        .then(async (res) => {
          if (isEmpty(res.data) || res.data.error != null) {
            setLoading(false);
            return;
          }
          res.data.map(async (data: any) => {
            let url = data.link;
            let ord: RulingModel = {
              type: 'ruling',
              title: data.court_case,
              url: url,
              startIndex: data.start_index ?? 0,
              endIndex: data.end_index ?? data.start_index + 1000,
              text: data.text_excerpt
                .replace('title=', ' ')
                .split(' ')
                .join(' '),
            };
            list.push(ord);
          });
          setOrdinances([...list]);
          onRulingLoad(list);
        });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loadedRulings.length > 0) {
      setOrdinances(loadedRulings);
      setLoading(false);
    } else {
      getRulings();
    }
    //eslint-disable-next-line
  }, [path]);

  useEffect(() => {
    if (id) {
      fetchSynthesis(id);
    }
    //eslint-disable-next-line
  }, []);

  const renderBox = () => {
    if (loading === true) {
      return (
        <Box
          height={'300px'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <CircularProgress />
        </Box>
      );
    } else if (ordinances.length === 0) {
      return (
        <Box
          height={'300px'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography fontStyle={'italic'} fontSize={'16px'}>
            Brak orzeczeń powiązanych z danym fragmentem
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box>
          {ordinances.map((ordinance, index) => {
            return (
              <Box
                key={index}
                sx={{ borderBottom: '1px solid lightgray', padding: '10px' }}
              >
                <Button
                  sx={{ textTransform: 'none' }}
                  onClick={() => {
                    onClick(ordinance);

                    addRuling(ordinance, id);
                    setDisplayedCase(ordinance);
                  }}
                >
                  {ordinance.title}
                </Button>
                <Typography fontSize={'14px'}>{ordinance.text}</Typography>
              </Box>
            );
          })}
        </Box>
      );
    }
  };

  const displayText = `${numberOfRulings} orzeczeń powiązanych z ${getText(
    path
  )}.`;
  return (
    <>
      <Typography fontWeight={'bold'}>{displayText}</Typography>
      {id != null && id !== '' ? (
        <>
          <Typography>
            {'Fragmenty orzeczeń zostały dobrane na podstawie konwersacji:'}
          </Typography>
          {/* <Typography fontStyle={"italic"}>{synthesis}</Typography> */}
          <SynthesisAccordion text={synthesis} />
          <Divider />
        </>
      ) : (
        <Typography fontStyle={'italic'}>
          {
            'Oto lista orzeczeń w przypadkowej kolejności. Zapytaj chat o problem, a tu pojawią się fragmenty w odniesieniu do niego.'
          }
        </Typography>
      )}
      {renderBox()}
    </>
  );
};

export default ListOfConnectedRulings;
