import React, { useCallback } from 'react';
import { Box, IconButton } from "@mui/material";
import PointView, { PointModel } from "./PointView";
import assets from "../../../../../../../../assets";
import { PathModel } from "../../LawPanel";

export interface ParagraphModel {
  name: string;
  text: string;
  highlighted?: boolean;
  points: PointModel[];
  numberOfRulings: number;
}

type Props = {
  paragraph: ParagraphModel;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rulingsNumber: number,
    endpoint: PathModel
  ) => void;
  articleName: string;
};

const ParagraphView: React.FC<Props> = React.memo(({ paragraph, onClick, articleName }: Props) => {
  
  const handleClick = useCallback((
    event: React.MouseEvent<HTMLButtonElement>,
    numberOfRulings: number,
    path?: PathModel | undefined
  ) => {
    let model: PathModel = {
      article: "",
      paragraph: "",
    };

    if (path != null) {
      model = path;
    }
    model.paragraph = paragraph.name;

    onClick(event, numberOfRulings, model);
  }, [onClick]);

  return (
    <>
      <div
        style={{
          marginLeft: "2px",
          marginBottom: "-3px",
          display: "flex",
          alignItems: "start",
        }}
      >
        {paragraph.name !== "" ? (
          <>
            {paragraph.numberOfRulings > 0 ? (
              <IconButton
                id={articleName + paragraph.name}
                onClick={(e) => handleClick(e, paragraph.numberOfRulings)}
                sx={{ padding: "6px" }}
              >
                <Box
                  borderRadius={"5px"}
                  component={"img"}
                  src={assets.icons.hammerBlack}
                  width="15px"
                  height={"15px"}
                />
              </IconButton>
            ) : (
              <IconButton disabled={true} sx={{ padding: "6px" }}>
                <Box width="15px" height={"15px"} />
              </IconButton>
            )}
            <div
              className={paragraph.highlighted ? "highlightedDiv" : "normalDiv"}
            >
              <div
                style={{ paddingBottom: "4px", paddingTop: "4px" }}
                dangerouslySetInnerHTML={{ __html: paragraph.text }}
              />
            </div>
          </>
        ) : null}
      </div>
      {paragraph.points.map((point, indexPoint) => {
        return (
          <PointView
            key={indexPoint}
            point={point}
            paragraphId={articleName + paragraph.name}
            onClick={handleClick}
          />
        );
      })}
    </>
  );
});

export default ParagraphView;
